/* eslint-disable*/
import React, { useEffect, useState, useMemo } from 'react';
//import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';
import moment from 'moment';
import { BigNumber } from '@ethersproject/bignumber';
import { formatEther, parseEther } from '@ethersproject/units';
import para_coin_bg from '../assets/images/para_coin_bg.gif';
import para_character from '../assets/images/character.png';
import logo from '../assets/images/logo.png';
import logo_mobile from '../assets/images/logo_mobile.png';
import logo_round from '../assets/images/logo_round.png';
import key from '../assets/images/key.png';
import metamask from '../assets/images/metamask.png';
import wallet_connect from '../assets/images/wallet_connect.png';
import coinbase from '../assets/images/coinbase.png';
import close from '../assets/images/close.png';
import spin_loader from '../assets/images/spin_loader.gif';
import menu from '../assets/images/menu.png';
import erc20Abi from '../abis/erc20.json';
import erc721Abi from '../abis/erc721.json';
import stakeAbi from '../abis/stake.json';
import { getStakesByUser } from '../apis/api';
import { getTokensOwnedByUser } from '../apis/nft';
//import { injected, walletconnect, walletlink } from '../utils/connector';
const { ethers, JsonRpcProvider } = require('ethers');
//const { AbiCoder } = require('ethers').utils;
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { useWeb3Modal } from '@web3modal/react'
import { useEthersSigner} from "../utils/ethersAdapter";


const Dashboard = () => {
    //const { address, library, activate } = useWeb3React();
    const { address, isConnected } = useAccount()
    const [library, setLibrary] = useState(new ethers.providers.JsonRpcProvider('https://nd-228-727-474.p2pify.com/aea5c6ad36629e7df631c286e3cff531'))
    const [data, setData] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [balance, setBalance] = useState(0);
    const [stakeAmount, setStakeAmount] = useState(0);
    const [totalStake, setTotalStake] = useState(0);
    const [totalToken, setTotalToken] = useState(0);
    const [lockPeriod, setLockPeriod] = useState(0);
    const [isApproved, setApproved] = useState(true);
    const [isAllApproved, setAllApproved] = useState(true);
    const [curIdx, setCurIdx] = useState(-1);
    const [isViewAll, setViewAll] = useState(false);
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [onMobileMenu, setMobileMenu] = useState(false);
    const [stakingUnlocked, setStakingUnlocked] = useState(false);
    const [preppers, setPreppers] = useState(false);
    const [OGpreppers, setOGpreppers] = useState(false);
    const [pros, setPros] = useState(false);

    const erc20Address = '0xD16fd95D949F996e3808EeEa0e3881C59E76EF1E';
    const stakeAddress = '0x8A9FFFD07F5bA3c7a8f4BCe81d04c2859efd6879';
    const erc721Address = ['0xd913B503F39324541cD21bb19d966a6F73032f81','0x77e807F6f548B9E1425b649090cB53e9cB077B20','0x0Cf1c30B2fEaeFfd708f088e5DcB3C082288A6a3'];
    const [nftApprovalRes, setNftApprovalRes] = useState([]);
    const { open, close } = useWeb3Modal()
    const signer = useEthersSigner();



    useEffect(() => {
        const provider = new ethers.providers.JsonRpcProvider('https://nd-228-727-474.p2pify.com/aea5c6ad36629e7df631c286e3cff531');

        const contract = new Contract(stakeAddress, stakeAbi, provider);
        contract.totalStake().then((res) => {
            const value = formatEther(res);
            setTotalStake(parseFloat(value).toFixed(0));
        });
        contract.totalToken().then((res) => {
            setTotalToken(res.toNumber());
        });
        contract.isUnlocked().then((res) => {
            setStakingUnlocked(res);

        });
    }, []);

    useEffect(() => {
        if (address) {

            const paraContract = new Contract(erc20Address, erc20Abi, library);
            paraContract.balanceOf(address).then((res) => {
                const balancePara = formatEther(res);
                setBalance(parseFloat(balancePara));
            });
            paraContract.allowance(address, stakeAddress).then((res) => {
                const allowance = formatEther(res);
                if (parseFloat(allowance) > 100000) setApproved(true);
                else setApproved(false);
            });

            const nftContract = new Contract(erc721Address[0], erc721Abi, library);

            var nftApprovals = [];
            nftContract.isApprovedForAll(address, stakeAddress).then((res) => {
                nftApprovals[0] = res

                //console.log(res)
            });

            const nftContract2 = new Contract(erc721Address[1], erc721Abi, library);
            nftContract2.isApprovedForAll(address, stakeAddress).then((res) => {
                nftApprovals[1] = res

                //console.log(res)
            });

            const nftContract3 = new Contract(erc721Address[2], erc721Abi, library);
            nftContract3.isApprovedForAll(address, stakeAddress).then((res) => {
                nftApprovals[2] = res

                //console.log(res)
            });



            setNftApprovalRes(nftApprovals)


            for (let x of erc721Address){

                //console.log(x)
                getTokensOwnedByUser(address,x).then((res) => {
                    if (res != null && res.length > 0) {
                        for (let n of res){
                            if (n.token_address.toLowerCase() == erc721Address[0].toLowerCase()){

                                setPreppers(true)
                            } else if (n.token_address.toLowerCase() == erc721Address[1].toLowerCase()){
                                setOGpreppers(true)
                            } else if (n.token_address.toLowerCase() == erc721Address[2].toLowerCase()){
                                setPros(true)
                            }
                            setTokens(t => ([...t,n]))
                        }
                    }

                    //console.log(res)
                    //console.log(tokens)
                });
            }


            if (nftApprovals[0] == true && nftApprovals[1] == true) {
                setAllApproved(true)
            } else {
                setAllApproved(false)
            }
            //console.log(nftApprovals);



        } else {
            setBalance(0);
        }
    }, [address, library]);

    useEffect(() => {
        if (address) {
            getStakesByUser(address).then(async(res) => {
                if (res == null || res.length < 1) setData([]);
                else {
                    for (let x of res) {
                        console.log(x)
                        console.log(x.transaction_hash)
                        console.log('here1')
                        const provider = new ethers.providers.JsonRpcProvider('https://nd-228-727-474.p2pify.com/aea5c6ad36629e7df631c286e3cff531');
                        console.log('here2')
                        const contract = new Contract(stakeAddress, stakeAbi, provider);
                        console.log('here3')
                        let value = await contract.getReward(x.id);
                        console.log('here4')
                        let formattedValue = await formatEther(value);
                        console.log('here5')
                        x.reward = formattedValue;
                        console.log('here6')
                        const tx = await provider.getTransaction(x.transaction_hash);
                        console.log('here7')
                        const inter = new ethers.utils.Interface(stakeAbi);
                        const decodedInput = inter.parseTransaction({ data: tx.data, value: tx.value});
                        console.log('here8')
                        var multi = 0
                        for (let c of decodedInput.args.collections){
                            if (c == erc721Address[0] || c == erc721Address[1]){
                                multi += 5
                            } else if (c == erc721Address[2]){
                                multi += 50
                            }
                        }
                        x.nftMultiplier = multi;
                    }
                    setData(res);
                };

                //console.log(res);
            });


            getStakesByUser(address).then(async(res) => {
                console.log(res)
            });
        } else {
            setBalance(0);
        }
    }, [address, library]);

    const connectMetamask = async () => {
        // if (window.ethereum) {
        // try {
        //  await window.ethereum.request({
        //     method: "eth_requestAccounts",
        //   });
        // } catch (err) {
        //   window.location.href="https://metamask.io/download";
        // }
        // }
        // const { ethereum } = window ;
        // if(window.web3.currentProvider.isMetaMask){
        //   console.log("Metamask is installed");
        // } else {
        //   window.location.href="https://metamask.io/download";
        // }
        // if (!ethereum) {
        //   return NotificationManager.error(
        //     'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
        //   );
        // }
        //return await activate(injected, (error) => console.log(error));
        // if (window.ethereum) {
        //   try {
        //     await activate(injected);
        //   } catch (ex) {
        //     console.log(ex);
        //   }
        // } else {
        //   window.open(
        //     'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en'
        //   );
        // }
    };

    const connectCoinbaseWallet = async () => {
        //return await activate(walletlink, (error) => console.log(error));
        // if (window.ethereum) {
        //   try {
        //     await activate(CoinbaseWallet);
        //   } catch (ex) {
        //     console.log(ex);
        //   }
        // } else {
        //   window.open(
        //     'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad?hl=en'
        //   );
        // }
    };

    const connectWalletConnect = async () => {
        //return await activate(walletconnect, (error) => console.log(error));
        //return await activate(walletconnect, (error) => console.log(error));
        // try {
        //   activate(WalletConnect, undefined, true)
        //     .catch((error) => {
        //         if (error) {
        //           console.log(error)
        //             activate(WalletConnect)
        //         } else {
        //             console.log('Pending Error Occured')
        //         }
        //     })
        // } catch (ex) {
        //   console.log(ex);
        // }
    };

    const getStakeDate = (dateString) => {
        const date = new Date(dateString);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return month + '-' + day + '-' + year;
    };

    const canUpdate = (dateString) => {
        const date = new Date(dateString);
        return Math.floor((Date.now() - date.getTime()) / (1000 * 3600 * 24)) >= 1;
    };

    const updateReward = (idx) => {
        if (idx > -1) {
            const contract = new Contract(stakeAddress, stakeAbi, library).connect(
                signer
            );
            setLoading(true);
            contract
                .updateReward(parseInt(data[idx].id, 10))
                .then((res) => {
                    setLoading(false);
                    res.wait().then(() => {
                        let curData = [...data];
                        curData[idx].updated_at = Date.now().toString();
                        setData(curData);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            console.log('not available');
        }
    };

    const claimReward = (idx) => {
        if (idx > -1) {
            const contract = new Contract(stakeAddress, stakeAbi, library).connect(
                signer
            );
            setLoading(true);
            contract
                .claimReward(parseInt(data[idx].id, 10))
                .then((res) => {
                    setLoading(false);
                    res.wait().then(() => {
                        let curData = [...data];
                        curData[idx].reward = '0';
                        setData(curData);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            console.log('not available');
        }
    };

    const getCurrentStakings = () => {
        let stakeInfo = {
            amount: 0,
            share: 0,
            token: 0,
            multiplier: 0,
            reward: 0,
        };
        data.forEach((item) => {
            stakeInfo.amount += parseFloat(item.amount);
            stakeInfo.reward += parseFloat(item.reward);
            stakeInfo.token += parseInt(item.token_count);
            stakeInfo.multiplier +=
                (parseInt(item.lock_option, 10) / 30) * 25 + parseInt(item.nftMultiplier);
        });
        stakeInfo.share =
            totalStake == 0
                ? 0
                : parseFloat(((stakeInfo.amount+stakeInfo.multiplier/100 * stakeInfo.amount) / totalStake)* 100).toFixed(2);

        return stakeInfo;
    };

    const getRemainTime = () => {
        const date = new Date(data[curIdx].started_at);
        const period =
            date.getTime() +
            1000 * 3600 * 24 * parseInt(data[curIdx].lock_option, 10) -
            Date.now();
        if (period > 0) {
            let day = period / (1000 * 3600 * 24);
            let hour = (period % (1000 * 3600 * 24)) / (1000 * 3600);
            let min = (period % (1000 * 3600)) / (1000 * 60);

            return (
                parseFloat(day).toFixed(0) +
                ' Days : ' +
                parseFloat(hour).toFixed(0) +
                ' Hrs : ' +
                parseFloat(min).toFixed(0) +
                ' Min'
            );
        }

        return '0 Days : 0 Hrs : 0 Min';
    };

    const canUnstake = () => {
        if (stakingUnlocked) return true;
        const date = new Date(data[curIdx].started_at);
        const period =
            date.getTime() +
            1000 * 3600 * 24 * parseInt(data[curIdx].lock_option, 10) -
            Date.now();
        if (period > 0) return false;
        else return true;
    };

    const approvePara = () => {
        const contract = new Contract(erc20Address, erc20Abi, library).connect(
            signer
        );
        setLoading(true);
        contract
            .approve(
                stakeAddress,
                parseEther(BigNumber.from('10000000000').toString())
            )
            .then((res) => {
                setLoading(false);
                res.wait().then(() => {
                    setApproved(true);
                    setLoading(false);
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const approveNft = () => {
        if (nftApprovalRes[0] == true || nftApprovalRes[1] == true || nftApprovalRes[2] == true){
            if (nftApprovalRes[0] == false){
                const contract = new Contract(erc721Address[0], erc721Abi, library).connect(
                    signer
                );
                setLoading(true);
                contract
                    .setApprovalForAll(stakeAddress, true)
                    .then((res) => {
                        setLoading(false);
                        res.wait().then(() => {
                            setAllApproved(true);
                            setLoading(false);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            } else if (nftApprovalRes[0] == false){
                const contract = new Contract(erc721Address[1], erc721Abi, library).connect(
                    signer
                );
                setLoading(true);
                contract
                    .setApprovalForAll(stakeAddress, true)
                    .then((res) => {
                        setLoading(false);
                        res.wait().then(() => {
                            setAllApproved(true);
                            setLoading(false);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });

            }
            else if (nftApprovalRes[0] == false){
                const contract = new Contract(erc721Address[2], erc721Abi, library).connect(
                    signer
                );
                setLoading(true);
                contract
                    .setApprovalForAll(stakeAddress, true)
                    .then((res) => {
                        setLoading(false);
                        res.wait().then(() => {
                            setAllApproved(true);
                            setLoading(false);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            }
        } else{
            for (let x of erc721Address){
                const contract = new Contract(x, erc721Abi, library).connect(
                    signer
                );
                setLoading(true);
                contract
                    .setApprovalForAll(stakeAddress, true)
                    .then((res) => {
                        setLoading(false);
                        res.wait().then(() => {
                            setAllApproved(true);
                            setLoading(false);
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            }
        }
    };

    const approvePreppers = () => {

        if (nftApprovalRes[0] == false){
            const contract = new Contract(erc721Address[0], erc721Abi, library).connect(
                signer
            );
            setLoading(true);
            contract
                .setApprovalForAll(stakeAddress, true)
                .then((res) => {
                    setLoading(false);
                    res.wait().then(() => {
                        setAllApproved(true);
                        let appr = [true,nftApprovalRes[1]];
                        setNftApprovalRes(appr);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });


        }};
    const approvePros = () => {

        if (nftApprovalRes[2] == false){
            const contract = new Contract(erc721Address[2], erc721Abi, library).connect(
                signer
            );
            setLoading(true);
            contract
                .setApprovalForAll(stakeAddress, true)
                .then((res) => {
                    setLoading(false);
                    res.wait().then(() => {
                        setAllApproved(true);
                        let appr = [true,nftApprovalRes[2]];
                        setNftApprovalRes(appr);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });


        }};

    const approveOGPreppers = () => {

        if (nftApprovalRes[1] == false){
            const contract = new Contract(erc721Address[1], erc721Abi, library).connect(
                signer
            );
            setLoading(true);
            contract
                .setApprovalForAll(stakeAddress, true)
                .then((res) => {
                    setLoading(false);
                    res.wait().then(() => {
                        setAllApproved(true);
                        let appr = [nftApprovalRes[0],true];
                        setNftApprovalRes(appr);
                        setLoading(false);
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });


        }};


    const handleStake = () => {
        console.log(selectedTokens);

        const contract = new Contract(stakeAddress, stakeAbi, library).connect(
            signer
        );
        let array = [];
        let tokensArray = [];
        selectedTokens.forEach((t) => {
            array.push(t.token_address);
            tokensArray.push(t.token_id);
        });
        setLoading(true);
        contract
            .stake(
                parseEther(stakeAmount.toString()),
                lockPeriod,
                array,
                tokensArray,
                {
                    gasLimit: 2000000
                }
            )
            .then((res) => {
                setLoading(false);
                res.wait().then((result) => {
                    const event = result.events.find((event) => event.event === 'Stake');
                    const id = event.args.id.toString();
                    const staker = event.args.address;
                    const lock_option = event.args.lockDays.toString();
                    const amount = formatEther(event.args.amount);
                    const token_count = event.args.tokens.length;
                    const newStake = {
                        id: id,
                        state: true,
                        amount: parseInt(amount, 10),
                        started_at: Date.now(),
                        lock_option: lock_option,
                        staker: staker,
                        reward: '0',
                        updated_at: Date.now(),
                        token_count: token_count,
                        block_number: result.blockNumber,
                    };
                    const newData = [...data];
                    newData.push(newStake);
                    setData(newData);

                    setSelectedTokens([]);
                    getTokensOwnedByUser(address).then((res) => {
                        if (res != null) setTokens(res);
                        else setTokens([]);
                        setStakeAmount(0);
                        setLoading(false);
                    });
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleUnstake = () => {
        console.log(data[curIdx].id)
        const contract = new Contract(stakeAddress, stakeAbi, library).connect(
            signer
        );
        setLoading(true);
        if (curIdx > -1) {
            contract
                .unstake(BigNumber.from(parseInt(data[curIdx].id),
                    {
                        gasLimit: 1000000
                    }))
                .then(async(res) => {
                    await setLoading(false);
                    res.wait().then(() => {
                        setCurIdx(-1);
                        const newData = [...data];
                        newData.splice(curIdx, 1);
                        setData(newData);
                        for (let x of erc721Address){
                            getTokensOwnedByUser(address,x).then((res) => {
                                if (res != null && res.length > 0) {
                                    setTokens(t => ([...t,res[0]]))
                                }
                                else setTokens([]);
                                //console.log(res)
                                console.log(tokens)
                            });
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    const handleClickImage = (token) => {
        //const id = parseInt(token_id, 10);
        const idx = selectedTokens.findIndex((element) => element === token);
        if (idx > -1) {
            const selTokens = [...selectedTokens];
            selTokens.splice(idx, 1);
            setSelectedTokens(selTokens);
        } else {
            const selTokens = [...selectedTokens];
            selTokens.push(token);
            setSelectedTokens(selTokens);
        }
    };

    const isTokenSelected = (token) => {
        return selectedTokens.includes(token);
    };

    return (
        <div className="select-none">
            <div className={isLoading ? 'opacity-75' : 'opacity-100'}>
                <div className="absolute flex w-full justify-between md:justify-start">
                    <img
                        className="ml-6 mt-5 md:ml-[196px] md:mt-5"
                        src={logo}
                        width={83}
                        height={83}
                        alt="logo"
                    />
                    <div className="absolute right-[196px] mt-5 hidden font-bold text-white md:block">
                        <ul className="flex text-lg">
                            <li className="text-bold  border-collapse cursor-pointer p-6 text-[18px] uppercase">
                <span
                    onClick={() => window.open('https://para.global')}
                    className="delay-50 btn border-none bg-transparent bg-none duration-100 hover:bg-transparent hover:text-para-green hover:transition-all"
                >
                  para.global
                </span>
                            </li>
                            <li className="text-bold  cursor-pointer p-6 text-[18px] uppercase">
                <span
                    onClick={() =>
                        window.open('https://app.uniswap.org/#/swap?chain=mainnet')
                    }
                    className="delay-50 btn border-none bg-transparent bg-none duration-100 hover:bg-transparent hover:text-para-green hover:transition-all"
                >
                  buy para
                </span>
                            </li>
                            <li className="text-bold cursor-pointer p-6 text-[18px]  uppercase">
                <span
                    onClick={() => window.open('https://opensea.io/ParaWerx')}
                    className="delay-50 btn border-none bg-transparent duration-100 hover:bg-transparent hover:text-para-green hover:transition-all"
                >
                  nfts
                </span>
                            </li>
                        </ul>
                    </div>
                    <img
                        className="mt-[45px] mr-[35px] h-[25px] w-[33px] text-white md:hidden"
                        src={menu}
                        alt="menu"
                        onClick={() => setMobileMenu(true)}
                    />
                </div>
                <div className="bg-banner-texture bg-cover font-poppins md:pb-[82px]">
                    <div className="block justify-center pt-[90px] md:flex md:pt-[220px]">
                        <div className="flex justify-center">
                            <img
                                className="mt-4 block w-1/2 md:mt-0 md:inline-block md:w-full"
                                src={para_coin_bg}
                                alt="para_coin_bg"
                            />
                        </div>
                        <div className="ml-0 mt-4 flex flex-col items-center justify-center md:ml-[25px] md:mt-0 md:items-start">
                            <p className="text-center text-[50px] font-bold uppercase leading-[54px] text-white md:text-left md:text-[64px]">
                                stake para
                            </p>
                            <p className="text-center text-[40px] font-bold uppercase leading-[54px]  text-para-green md:text-left md:text-[64px]">
                                earn rewards
                            </p>
                            <p className="leading-[54px} my-[10px] w-1/2 text-center text-[12px] text-base font-bold text-white md:my-[14px] md:w-full md:text-left md:text-[15px]">
                                Each NFT staked multiplies your original staked token amount
                            </p>
                        </div>
                    </div>
                    <div className="hidden justify-center md:flex">
                        <div className="relative mt-[105px] flex justify-around rounded-[10px] border-2 border-para-green">
                            <div className="flex flex-col items-center justify-center px-[95px] py-10">
                                <p className="text-[40px] font-bold uppercase leading-[54px] text-para-green underline underline-offset-1">
                                    staked para
                                </p>
                                <p className="text-[30px] font-bold uppercase leading-[54px] text-white">
                                    {totalStake.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                            </div>
                            <div className="ml-[40px] flex flex-col items-center justify-center px-[95px] py-10">
                                <p className="text-[40px] font-bold uppercase leading-[54px] text-para-green underline underline-offset-1">
                                    staked nfts
                                </p>
                                <p className="text-[30px] font-bold uppercase leading-[54px] text-white">
                                    {totalToken}
                                </p>
                            </div>
                            <img
                                className="absolute -mt-[108px] inline-block"
                                src={para_character}
                                alt="para_coin_character"
                            />
                        </div>
                    </div>
                    <div className="flex translate-y-8 justify-center md:hidden md:translate-y-0">
                        <img
                            className="inline-block h-[330px] w-[157px]"
                            src={para_character}
                            alt="para_coin_character"
                        />
                        <div className="flex -translate-y-3 flex-col justify-center text-[20px]">
                            <div className="ml-[10px] mb-5 flex w-[194px] flex-col items-center justify-center rounded-[10px] border-2 border-para-green p-5">
                                <p className="font-bold uppercase text-para-green underline underline-offset-1">
                                    staked para
                                </p>
                                <p className="font-bold uppercase text-white">{totalStake.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                            </div>
                            <div className="ml-[10px] flex w-[194px] flex-col items-center justify-center rounded-[10px] border-2 border-para-green p-5">
                                <p className="font-bold uppercase text-para-green  underline underline-offset-1">
                                    staked nfts
                                </p>
                                <p className="font-bold uppercase text-white">{totalToken}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full px-2 pt-[38px] md:px-[196px] md:pt-[156px]">
                    <div className="flex items-center justify-center rounded-t-[20px] bg-para-green px-[26px] py-[22px] md:justify-between">
            <span className="text-center text-[30px] font-bold text-white md:text-[50px]">
              PARA + NFT STAKING
            </span>
                        <div className="hidden cursor-pointer md:block">
                            <label

                                onClick={() => open()}
                                className="delay-50 modal-button btn rounded-[10px] border-2 border-white  bg-[#344646] px-8 py-2 text-center font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.25)] duration-100 hover:border-white hover:bg-para-green  hover:transition-all md:text-[15px] lg:text-[20px] xl:text-[25px]"
                            >
                <span>
                  {address === undefined
                      ? 'connect wallett'
                      : address.slice(0, 4) +
                      '...' +
                      address.slice(-4) +
                      '   connected'}
                </span>
                            </label>
                        </div>
                    </div>
                    <div className="mx-[18px] mt-[23px] flex cursor-pointer justify-center md:hidden">
                        <label
                            onClick={() => open()}
                            className="delay-50 modal-button btn  rounded-[10px] border-2 border-white bg-[#344646] px-8 py-2 text-center text-[25px] font-bold uppercase text-white  shadow-[0_4px_4px_rgba(0,0,0,0.25)] duration-100 hover:border-white hover:bg-para-green hover:transition-all"
                        >
                            {address === undefined
                                ? 'connect wallet'
                                : address.slice(0, 4) +
                                '...' +
                                address.slice(-4) +
                                '   connected'}
                        </label>
                    </div>
                    <div className="grid divide-x divide-dashed divide-black divide-opacity-50 rounded-b-[20px] border-[1px] border-[#E5E5E5] bg-[#F7F7F7] px-[18px] py-12 text-[#344646] md:grid-cols-2 md:px-[56px] md:py-12">
                        <div className="md:pr-10">
                            <div className="flex items-center">
                                <div className="flex items-center justify-center rounded-full py-[6px] pl-[10px] pr-2">
                                    <img src={logo_round} width={50} height={50} alt="logo" />
                                </div>
                                <div className="ml-[17px] flex flex-col justify-center text-[#344646]">
                                    <p className="text-[25px] font-semibold uppercase">para</p>
                                    <div>
                                        <span className="text-xs">Your Balance: </span>
                                        <span className="text-xs font-bold">
                      {balance.toFixed(2)}
                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 flex justify-between md:mt-10">
                                <input
                                    className="mr-[17px] block w-full appearance-none rounded border-[1px] border-[#979393] px-3 leading-tight text-[#979393]"
                                    type="number"
                                    placeholder="Stake Amount"
                                    value={stakeAmount}
                                    onChange={(e) => setStakeAmount(e.target.value)}
                                />
                                <button
                                    type="button"
                                    onClick={() => setStakeAmount(balance-1)}
                                    className="btn block rounded-r border-[1px] border-[#979393] bg-[#D9D9D9] py-2 px-2 text-[13px] font-normal uppercase text-[#344646]
              hover:text-white"
                                >
                                    max
                                </button>
                            </div>
                            <div className="mt-[27px] w-full border-t-[1px] border-dashed border-black border-opacity-50" />
                            <div className="flex items-center py-[30px]">
                                <img
                                    className="mr-[18px] "
                                    src={key}
                                    width={25}
                                    height={33}
                                    alt="key"
                                />
                                <div className="w-[200px] text-[#344646]">
                  <span className="block text-[20px] font-semibold">
                    Lock for
                  </span>
                                    0 - 120 &nbsp; Days{' '}
                                    <span className="block text-[12px] font-normal"></span>
                                </div>
                                <select
                                    onChange={(e) => setLockPeriod(e.target.value)}
                                    className="w-full cursor-pointer rounded border border-[#979393] py-2 px-4 pr-8 leading-tight text-[#979393]  focus:bg-white focus:outline-none"
                                >
                                    <option value={0}>0 days</option>
                                    <option value={30}>30 days</option>
                                    <option value={60}>60 days</option>
                                    <option value={90}>90 days</option>
                                    <option value={120}>120 days</option>
                                </select>
                            </div>
                            <div className="text-black">
                                <p className="pb-2 text-xs font-bold">l
                                    Lock Bonus Multiplier of Original Staked Token Amount :
                                </p>
                                <p className="text-xs font-semibold">
                                    30 Days: 25%&nbsp; |&nbsp; 60 Days: 50%&nbsp; |&nbsp; 90
                                    Days:75% |&nbsp; 120 Days:100%
                                </p>
                            </div>
                            <div className="flex pt-[26px]">
                                <button
                                    type="button"
                                    disabled={isApproved}
                                    onClick={approvePara}
                                    className="delay-50 btn ml-auto w-full rounded-[3px] rounded-l border-2  border-white bg-[#344646] py-2 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:border-white hover:bg-para-green hover:transition-all md:w-[200px]"
                                >
                                    Approve Para
                                </button>
                            </div>
                            <div className="my-[27px] w-full border-t-[1px] border-dashed border-black border-opacity-50" />
                            <div className="text-[#344646]">
                                <p className="mx-2 inline-block text-[25px] font-normal uppercase md:mx-0">
                                    stake nfts
                                </p>
                                <span> ( 20 Max )</span>
                                <div className="flex pb-[26px] pt-3">
                                    <div className="grid w-full grid-cols-5 gap-4">

                                        {tokens.map((token, index) => {
                                            const url = JSON.parse(token.metadata).image;
                                            if (isViewAll || index < 4)
                                                return (
                                                    <img
                                                        src={url}
                                                        alt="nft-img"
                                                        onClick={() => handleClickImage(token)}
                                                        style={{
                                                            opacity: isTokenSelected(token)
                                                                ? 1
                                                                : 0.5,
                                                        }}
                                                        className="h-[58px] w-[58px] cursor-pointer border-[1px] border-[#979393] xl:h-[68px] xl:w-[68px] 2xl:h-[83px] 2xl:w-[83px]"
                                                    />
                                                );

                                            return null;
                                        })}
                                        <div
                                            onClick={() => setViewAll(!isViewAll)}
                                            className="flex h-[58px] w-[58px] cursor-pointer flex-col items-center justify-center border-[1px] border-[#979393] text-[15px] font-bold xl:h-[68px] xl:w-[68px] 2xl:h-[83px] 2xl:w-[83px]"
                                        >
                      <span className="block uppercase text-para-green">
                        view
                      </span>
                                            <span className="block uppercase text-para-green">
                        all
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-[27px] w-full border-t-[1px] border-dashed border-black border-opacity-50" />
                            <div className="flex">
                                <button
                                    type="button"
                                    disabled={stakeAmount > 0 && address && isApproved ? false : true}
                                    onClick={
                                        selectedTokens.length < 1 && stakeAmount > 0
                                            ? handleStake
                                            : preppers && nftApprovalRes[0] == false
                                                ? approvePreppers
                                                : OGpreppers && nftApprovalRes[1] == false
                                                    ? approveOGPreppers
                                                    : pros && nftApprovalRes[2] == false
                                                        ? approvePros
                                                        : handleStake}

                                    className="delay-50 btn ml-auto w-full rounded-[3px] rounded-l border-2 border-white bg-[#344646] py-2 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:border-white hover:bg-para-green  hover:transition-all md:w-[250px]"
                                >
                                    {selectedTokens.length < 1
                                        ? 'stake para'
                                        : preppers && nftApprovalRes[0] == false
                                            ? 'approve Preppers'
                                            : OGpreppers && nftApprovalRes[1] == false
                                                ? 'approve OG Preppers'
                                                : pros && nftApprovalRes[2] == false
                                                    ? 'approve Para Pros'
                                                    : 'stake para/nft(s)'}


                                </button>
                            </div>
                        </div>
                        <div className="hidden pl-10 font-poppins text-[25px] font-normal text-[#344646] md:block">
                            <div className="flex justify-between pb-5">
                                <p className="mr-6 py-1 uppercase 2xl:mr-10">dashboard</p>
                                <select
                                    className="w-full cursor-pointer rounded border border-[#979393] py-2 px-4 pr-8 leading-tight text-[#979393]  focus:bg-white focus:outline-none"
                                    placeholder="CURRENT STAKINGS"
                                    onChange={(e) => setCurIdx(e.target.value)}
                                >
                                    <option value={-1} selected>
                                        CURRENT STAKINGS
                                    </option>
                                    {data.map((item, index) => (
                                        <option value={index}>
                                            {moment(item.started_at).format("MM/DD/YYYY")}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <table className="w-full table-fixed">
                                <tbody>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50 py-8  pl-5 text-[20px]">
                                        PARA Staked
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px]  font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? data[curIdx].amount
                                            : 0.00}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50 py-8 pr-1 pl-5 text-[20px]">
                                        % of staking pool
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px] font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? parseFloat(
                                                ((data[curIdx].amount + (data[curIdx].nftMultiplier + ((parseInt(data[curIdx].lock_option, 10) / 30) * 25)) / 100 * data[curIdx].amount) / totalStake)* 100).toFixed(10)
                                            : 0.00}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black  border-opacity-50 pl-5 text-[20px]">
                                        NFTs Staked
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 py-8 text-center  text-[15px] font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? data[curIdx].token_count
                                            : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50  py-8 pl-5 text-[20px]">
                                        % Multiplier
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center  text-[15px] font-bold text-[#D3AE6C] ">
                                        {curIdx > -1
                                            ? data[curIdx].nftMultiplier +
                                            (parseInt(data[curIdx].lock_option, 10) / 30) * 25
                                            : 0.0000}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50  py-8 pl-5 text-[20px]">
                                        Rewards to Claim
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px] font-bold text-[#D3AE6C] ">
                                        {curIdx > -1
                                            ? parseFloat(data[curIdx].reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : parseFloat(getCurrentStakings().reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div>
                                <div className="text-black">
                  <span className="block pl-5 pt-10 text-[15px] font-bold">
                    Unlocks in:
                  </span>
                                    <span className="block pl-5 text-xs font-normal">
                    {curIdx < 0 ? '-- Days : -- Hrs : -- Min' : getRemainTime()}
                  </span>
                                </div>
                                <div className="mt-3 flex items-center justify-between md:flex-col 2xl:flex-row">

                                    <button
                                        type="button"
                                        disabled={curIdx < 0}
                                        onClick={() => claimReward(curIdx)}
                                        className="delay-50 b-0 btn rounded-[3px] rounded-l border-2 border-white bg-[#344646] py-2 px-4 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:border-white hover:bg-para-green hover:transition-all md:mb-2 md:w-full 2xl:mb-0 2xl:w-auto"
                                    >
                                        claim reward
                                    </button>
                                    <button
                                        type="button"
                                        disabled={curIdx < 0 || !canUnstake()}
                                        onClick={handleUnstake}
                                        className="delay-50 b-0 btn rounded-[3px] rounded-l border-2 border-white bg-[#344646] py-2 px-4 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:border-white hover:bg-para-green hover:transition-all md:w-full 2xl:w-auto"
                                    >
                                        unstake
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[25px] rounded-b-[20px] bg-[#F6F6F6] font-poppins text-[25px] font-normal text-[#344646] md:hidden">
                        <div className="flex justify-between pb-5">
                            <div className="flex w-full items-center justify-center rounded-t-[20px] bg-[#D3AE6C] py-[22px]">
                <span className="text-center text-[30px] font-bold uppercase text-white md:text-center md:text-[50px]">
                  user dashboard
                </span>
                            </div>
                        </div>
                        <div className="px-[17px]">
                            <select
                                className="w-full rounded border border-[#979393] py-2 px-4 pr-8 leading-tight text-[#979393]  focus:bg-white focus:outline-none"
                                placeholder="CURRENT STAKINGS"
                                onChange={(e) => setCurIdx(e.target.value)}
                            >
                                <option value={-1} selected>
                                    CURRENT STAKINGS
                                </option>
                                {data.map((item, index) => (
                                    <option value={index}>{moment(item.started_at).format("MM/DD/YYYY")}</option>
                                ))}
                            </select>
                            <table className="mt-6 w-full table-fixed">
                                <tbody>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50 py-8  pl-5 text-[20px]">
                                        PARA Staked
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px]  font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? data[curIdx].amount
                                            : getCurrentStakings().amount}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50 py-8 pr-1 pl-5 text-[20px]">
                                        % of staking pool
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px] font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? parseFloat(
                                                ((data[curIdx].amount + (data[curIdx].nftMultiplier + ((parseInt(data[curIdx].lock_option, 10) / 30) * 25)) / 100 * data[curIdx].amount) / totalStake)* 100).toFixed(10)
                                            : 0.00}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black  border-opacity-50 pl-5 text-[20px]">
                                        NFTs Staked
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 py-8 text-center  text-[15px] font-bold text-[#D3AE6C]">
                                        {curIdx > -1
                                            ? data[curIdx].token_count
                                            : getCurrentStakings().token}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50  py-8 pl-5 text-[20px]">
                                        % Multiplier
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center  text-[15px] font-bold text-[#D3AE6C] ">
                                        {curIdx > -1
                                            ? data[curIdx].nftMultiplier +
                                            (parseInt(data[curIdx].lock_option, 10) / 30) * 25
                                            : 0.0000}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="border-y-[1px] border-dashed border-black border-opacity-50  py-8 pl-5 text-[20px]">
                                        Rewards to Claim
                                    </td>
                                    <td className="border-collapse border-y-[1px] border-l-[1px] border-dashed border-black border-opacity-50 text-center text-[15px] font-bold text-[#D3AE6C] ">
                                        {curIdx > -1
                                            ? parseFloat(data[curIdx].reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : parseFloat(getCurrentStakings().reward).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="px-6 text-center">

                            <button
                                type="button"
                                disabled={curIdx < 0}
                                onClick={() => claimReward(curIdx)}
                                className="delay-50 b-0 mt-6 ml-auto w-full rounded-[3px] rounded-l border-2 border-white bg-[#344646] py-2 px-3 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:bg-para-green hover:transition-all"
                            >
                                claim reward
                            </button>
                            <button
                                type="button"
                                disabled={curIdx < 0 || !canUnstake()}
                                onClick={handleUnstake}
                                className="delay-50 b-0 mt-6 ml-auto w-full rounded-[3px] rounded-l border-2 border-white bg-[#344646] py-2 px-3 text-[18px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.15)] duration-100 hover:bg-para-green hover:transition-all"
                            >
                                unstake
                            </button>
                            <div className="pb-4 text-black">
                <span className="block pl-5 pt-6 text-[15px] font-bold">
                  Unlocks in:
                </span>
                                <span className="block pl-5 text-xs font-normal">
                  {curIdx < 0 ? '-- Days : -- Hrs : -- Min' : getRemainTime()}
                </span>
                            </div>
                        </div>
                    </div>
                    <div className="pt-[50px]">
                        <p className="mb:ml-0 ml-2 mb-2 text-[25px] font-normal uppercase">
                            current staking position(s)
                        </p>
                        <div className="mb-5 w-full text-[10px] md:text-[12px]">
                            <div className="overflow-hidden rounded border-b border-gray-200 shadow">
                                <table className="min-w-full bg-white">
                                    <thead className="border-[1px] border-[#E5E5E5] bg-[#F6F6F6] text-[12px] font-bold text-[#344646]">
                                    <tr>
                                        <th className="py-3 px-3 text-center text-xs font-semibold uppercase md:text-[15px]">
                                            date
                                        </th>
                                        <th className="py-3 px-3 text-center text-xs font-semibold uppercase md:text-[15px]">
                                            tokens
                                        </th>
                                        <th className="py-3 px-3 text-center text-xs font-semibold uppercase md:text-[15px]">
                                            nfts
                                        </th>
                                        <th className="py-3 px-3 text-center text-xs font-semibold uppercase md:text-[15px]">
                                            days locked
                                        </th>
                                        {/* <tr className="py-3 px-3 text-center text-xs font-semibold uppercase md:text-[15px]" /> */}
                                    </tr>
                                    </thead>
                                    <tbody className="text-gray-700">
                                    {data.map((item, index) => (
                                        <tr
                                            className={
                                                index % 2
                                                    ? 'cursor-pointer bg-[#F6F6F6]'
                                                    : 'cursor-pointer'
                                            }
                                            onClick={() => setCurIdx(index)}
                                        >
                                            <td className="py-3 px-3 text-center font-normal text-black">
                                                {moment(item.started_at).format("MM/DD/YYYY")}
                                            </td>
                                            <td className="py-3 px-3 text-center font-normal text-black">
                                                {item.amount}
                                            </td>
                                            <td className="py-3 px-3 text-center font-normal text-black">
                                                {item.token_count}
                                            </td>
                                            <td className="py-3 px-3 text-center font-normal text-black">
                                                {item.lock_option}
                                            </td>
                                            {/* <td className="py-3 px-3 text-center font-normal text-black">
                        <div
                          onClick={() =>
                            updateReward(
                              canUpdate(item.updated_at) ? index : -1
                            )
                          }
                          className="hover:text-black-500 cursor-pointer text-[8px] font-bold uppercase text-black underline md:text-xs"
                        >
                          {canUpdate(item.updated_at)
                            ? 'update dashboard'
                            : 'not available'}
                        </div>
                      </td> */}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="px-6 text-[#545454] md:px-0">
                        <p className="mt-[50px] text-[25px] uppercase">Legal DISCLAIMER</p>
                        <div className="text-[13px]">
                            <p className="">
                                By participating in the staking of PARA Tokens &amp; PARA NFT’s.
                                ("PARA Staking"), each participating individual and/or
                                organization ("Participant") accepts and agrees that, to the
                                extent permitted by law, Wrinkle Werx Inc. including all
                                directly and indirectly affiliated and unaffiliated companies,
                                projects, utilities, owners, board members, executive officers,
                                shareholders, token holders, coin holders, employees and
                                contractors disclaim all liability, damages, cost, loss or
                                expense (including, without limitation, legal fees, costs and
                                expenses) to it in respect of its involvement in PARA Staking.
                                Each Participant should carefully consider all factors involved
                                in participating in Staking, including, but not limited to,
                                those listed below and, to the extent necessary, consult an
                                appropriate professional or other expert (including an expert in
                                cryptographic tokens, blockchain-based software systems, and all
                                affiliated or unaffiliated systems). If any of the following
                                considerations are unacceptable to a Participant, that
                                Participant should not be involved in PARA Staking. These
                                considerations are not intended to be exhaustive and should be
                                used as guidance only.
                            </p>
                            <ul className="list-disc pl-8">
                                <li className="py-3">
                                    PARA Staking is made available to the public, and Wrinkle Werx
                                    Inc. including all directly and indirectly affiliated and
                                    unaffiliated companies, projects, utilities, owners, board
                                    members, executive officers, shareholders, token holders, coin
                                    holders, employees and contractors expressly disclaims any
                                    liability in respect of any actions, programs, applications,
                                    developments, and operations of PARA Staking.
                                </li>
                                <li className="py-3">
                                    Hackers, individuals, other malicious groups, or organizations
                                    may attempt to interfere with the Ethereum Blockchain, Wrinkle
                                    Werx DAG Protocol, PARA Token, WERX Coins, PARA NFT’s, PARA
                                    Staking and all affiliated and unaffiliated projects and
                                    utilities in a variety of ways such as cryptographic attacks,
                                    malware attacks, denial of service attacks, consensus-based
                                    attacks, sybil attacks, smurfing, and/or spoofing.
                                </li>
                                <li className="py-3">
                                    The regulatory status of cryptographic tokens, directed
                                    acyclic graph protocols, blockchain and distributed ledger
                                    technology as well as its applications, utilities and projects
                                    are unclear or unsettled in many jurisdictions and it is
                                    difficult to predict how or whether governments or regulatory
                                    agencies may implement changes to law or apply existing
                                    regulation with respect to such technology and its
                                    applications, including the Wrinkle Werx DAG Protocol, PARA
                                    Token, WERX Coins, PARA NFT’s, PARA Staking and all affiliated
                                    and unaffiliated projects and utilities.
                                </li>
                                <li className="py-3">
                                    The PARA Tokens, WERX Coins and PARA NFT’s are not intended to
                                    represent any formal or legally binding investment.
                                    Cryptographic tokens that possess value in public markets,
                                    such as Ether and Bitcoin, have demonstrated extreme
                                    fluctuations in price over short periods of time on a regular
                                    basis. Participants should be prepared to expect similar
                                    fluctuations in the price of the PARA Tokens, WERX Coins and
                                    PARA NFT’s with the possibility of Participants experiencing a
                                    complete and permanent loss of their initial purchase.
                                </li>
                            </ul>
                            <p>
                                The Wrinkle Werx DAG Protocol, PARA Tokens, WERX Coins, Para
                                NFT’s and all affiliated projects and utilities are NOT intended
                                to be securities (or any other regulated instrument) under the
                                laws of any jurisdiction where they are intended to be, or will
                                be, purchased or sold. Accordingly, Para Tokens, Werx Coins,
                                Para NFT’s and all affiliated and unaffiliated past, current and
                                future projects and utilities may not be offered or sold,
                                directly or indirectly, by any holder, in or from any country or
                                jurisdiction, except in circumstances which will result in
                                compliance with all applicable rules and regulations of any such
                                country or jurisdiction.
                            </p>
                        </div>
                    </div>
                </div>
                <input type="checkbox" id="my-modal" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box bg-[#D3AE6C] text-[#F7F7F7]">
                        <p className="pb-4 text-lg text-[24px] font-bold uppercase">
                            connect wallet
                        </p>
                        <label htmlFor="my-modal" className="cursor-pointer">
                            <img
                                className="absolute right-[19px] top-[30px] h-[27px] w-[27px]"
                                src={close}
                                width={26}
                                height={26}
                                alt="close"
                            />
                        </label>
                        {/* <BrowserView> */}
                        <label htmlFor="my-modal" className="cursor-pointer">
                            <div
                                onClick={() => connectMetamask()}
                                role="alert"
                                className="mx-5 mt-[18px] flex cursor-pointer items-center justify-center border-[1px] border-[#344646] text-white"
                            >
                                <img
                                    className="mr-[30px] inline-block py-4"
                                    src={metamask}
                                    width={60}
                                    height={60}
                                    alt="metamask"
                                />
                                <span className="text-[20px] font-bold">MetaMask</span>
                            </div>
                        </label>
                        {/* </BrowserView> */}
                        <label htmlFor="my-modal" className="cursor-pointer">
                            <div
                                onClick={() => connectWalletConnect()}
                                role="alert"
                                className="mx-5 mt-[18px] flex cursor-pointer items-center justify-center border-[1px] border-[#344646] text-white"
                            >
                                <img
                                    className="mr-[30px] inline-block py-4"
                                    src={wallet_connect}
                                    width={60}
                                    height={60}
                                    alt="wallet_connect"
                                />
                                <span className="text-[20px] font-bold">Wallet Connect</span>
                            </div>
                        </label>
                        <label htmlFor="my-modal" className="cursor-pointer">
                            <div
                                onClick={() => connectCoinbaseWallet()}
                                role="alert"
                                className="mx-5 mt-[18px] flex cursor-pointer items-center justify-center border-[1px] border-[#344646] text-white"
                            >
                                <img
                                    className="mr-[30px] inline-block py-4"
                                    src={coinbase}
                                    width={60}
                                    height={60}
                                    alt="coinbase"
                                />
                                <span className="text-[20px] font-bold">Coinbase Wallet</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div>
                    <div
                        id="medium-modal"
                        tabindex="-1"
                        className="h-modal fixed top-0 right-0 left-0 z-50 w-full overflow-y-auto overflow-x-hidden md:inset-0 md:h-full"
                    >
                        <img
                            className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                            src={spin_loader}
                            alt="spin_loader"
                        />
                    </div>
                </div>
            )}
            {onMobileMenu && (
                <div className="fixed top-0 left-0 h-screen w-screen select-none font-poppins font-bold text-white md:hidden ">
                    <div className="flex flex-col items-center bg-[#D3AE6C] px-5">
                        <img
                            className="bottom-12 mt-[37px] text-center"
                            src={logo_mobile}
                            width={85}
                            height={112}
                            alt="logo_mobile"
                        />
                        <img
                            className="absolute right-[33px] top-12 h-[27px] w-[27px] md:hidden"
                            src={close}
                            width={26}
                            height={26}
                            alt="close"
                            onClick={() => setMobileMenu(false)}
                        />
                        <div className="mx-8 mt-16 h-[1px] w-full bg-[#344646]"></div>
                        <div
                            className="flex h-[112px] items-center text-center text-lg uppercase"
                            onClick={() => window.open('https://para.global')}
                        >
                            para.global{' '}
                        </div>
                        <div className="h-[1px] w-full bg-[#344646]"></div>
                        <div
                            className="flex h-[112px] items-center text-center text-lg uppercase"
                            onClick={() =>
                                window.open('https://app.uniswap.org/#/swap?chain=mainnet')
                            }
                        >
                            {' '}
                            buy para{' '}
                        </div>
                    </div>
                    <div className="bg-[#D3AE6C]">
                        <div className="mx-8">
                            <div className="h-[1px] w-full bg-[#344646]"></div>
                            <div
                                className="flex h-[112px] items-center justify-center text-lg uppercase"
                                onClick={() => window.open('https://opensea.io/ParaWerx')}
                            >
                                nfts
                            </div>
                            <div className="h-[1px] w-full bg-[#344646]"></div>
                        </div>
                    </div>
                    <div className="h-full border-white bg-[#D3AE6C] px-[27px] py-11">
                        <label
                            htmlFor="my-modal"
                            className="delay-50 flex w-full items-center justify-center rounded-[10px] border-2 bg-[#344646] py-1 px-10 text-center text-[20px] font-bold uppercase text-white shadow-[0_4px_4px_rgba(0,0,0,0.25)] duration-100 hover:bg-para-green hover:transition-all"
                            onClick={() => setMobileMenu(true)}
                        >
              <span>
                {address === undefined ? 'connect wallet' : 'connected'}
              </span>
                        </label>
                    </div>
                </div>
            )}

            <div className="relative mt-20 flex h-[116px] justify-center pt-10 pb-20 md:px-0">
                <img
                    className="absolute bottom-12"
                    src={logo}
                    width={85}
                    height={112}
                    alt="bottom_logo"
                />
            </div>
        </div>
    );
};

export default Dashboard;
