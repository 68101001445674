import Axios from 'axios';



export async function getStakesByUser(user_address) {
  const result = await Axios.get('https://stakes.wrinklewerx.com/getUserStakes', {
    params: {
      address: user_address
    },
  }).then(res => {
    return res.data;
  }).catch(err => {
    console.log(err);
    return null;
  });

  return result;

}


