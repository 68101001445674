import Axios from 'axios';

const baseURL = 'https://deep-index.moralis.io/api/v2/';

const api_key =
  's19BlyGw6CNDwJ5PRPXIRAYTDczPm2eOSojl09fmMLy2IbGGY7FflYU6PDMLHhpP';

//const collection_address = '0xd913B503F39324541cD21bb19d966a6F73032f81';

export async function getTokensOwnedByUser(user_address,collection_address) {
  const url = baseURL + user_address + '/nft/' + collection_address;
  const result = await Axios.get(url, {
    headers: {
      'x-api-key': api_key,
    },
    params: {
      chain: 'eth',
      format: 'decimal',
      limit: 100
    }
  }).then(res => {
    return res.data.result;
  }).catch(err => {
    console.log(err);
    return null;
  });

  return result;
}
